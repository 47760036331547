import React from 'react';
import './customFooter.css';
import logoTheme from '../../../images/fav.png';


const CustomFooter = () => {
    const currentYear = new Date().getFullYear();
    return(
        <>
        <div className="row">
        <footer className="footer">
      <div className="footer-content">
        <img src={logoTheme} alt="Lincoln University" className='theme-logo' />
        <div className='input-box'>
        <p className="address">
          401 15th Street<br />
          Oakland, CA 94612<br />
          Email: <a style={{color:"#900000"}} href="mailto:admissions@lincolnuca.edu">admissions@lincolnuca.edu</a><br />
          Phone:  <a style={{color:"#000", textDecoration:"none"}} href="tel:510-628-8010">510-628-8010</a>
        </p>
        </div>
        <div className='input-box'>
        <p className="technical-assistance">
          For Technical Assistance<br />
          Email: <a style={{color:"#900000"}} href="mailto:helpdesk@lincolnuca.edu">helpdesk@lincolnuca.edu</a><br />
          Phone: <a style={{color:"#000", textDecoration:"none"}} href="tel:510-628-8020">510-628-8020</a>
        </p>
        </div>
        <div className='input-box'>
        <div className="footer-links">
          <a href="https://lincolnuca.edu/">Home</a>
          <a style={{color:"#900000", textDecoration:"none"}}>Create Account</a>
          <a href="https://uat-students.icore.uk/">Login</a>
          <a href="/show-privacy-policy" target='_blank'>Privacy Policy</a>
        </div>
        </div>
        <div className='input-box'>
        <p className="copyright">
          &copy; {currentYear} Enrollment Application | Lincoln University
        </p>
        </div>
      </div>
    </footer>
        </div>
        </>
    );
}

export default CustomFooter;