import { useParams } from "react-router-dom";
import AcademicsStripePayment from "./Payment/academics_stripe_payment";
import AcademicsViewBilling from "./academics_view_billing";
import { useEffect, useState } from "react";
import { BILLING_PERIOD } from "../../../apis/apis";
import SisLoader from "../../../widgets/loader";



const AcademicsBilling = () => {
    const [loader, set_loader] = useState(true);
    const[VIEW_BILLING_PERIOD_API_RESPONSE,set_VIEW_BILLING_PERIOD_API_RESPONSE] = useState({});
    const[upfront_amount_payment_indent_json,set_upfront_amount_payment_indent_json] = useState("");

    const {sem_id,calendar_id,amount} = useParams();


useEffect(()=>{
    VIEW_BILLING_PERIOD();
  },[]);

  const VIEW_BILLING_PERIOD = async() => {

    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("student_id", USER_DATA.id);
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("semester_id", parseInt(atob(sem_id)));
    FORM_DATA.append("acad_calender_id", parseInt(atob(calendar_id)));
    const VIEW_BILLING_PERIOD_API_RES = await BILLING_PERIOD(FORM_DATA);
    if(VIEW_BILLING_PERIOD_API_RES?.data?.status)
    {
        set_VIEW_BILLING_PERIOD_API_RESPONSE(VIEW_BILLING_PERIOD_API_RES);
        set_upfront_amount_payment_indent_json(VIEW_BILLING_PERIOD_API_RES?.data?.student_final_bill?.upfront_amount_payment_indent_json);
      set_loader(false);
    }
    else{
      set_loader(false);
    }
  }

    return (
        <>
        {loader ? <SisLoader/> : <>
            <div className="row">
                <div className="col-7" style={{borderRight:"1px solid #666"}}>
                    <AcademicsViewBilling VIEW_BILLING_PERIOD_API_RESPONSE = {VIEW_BILLING_PERIOD_API_RESPONSE} />
                </div>
                <div className="col-5">
                    <AcademicsStripePayment upfront_amount_payment_indent_json = {upfront_amount_payment_indent_json} type="upfront" plan_id={null} amount={amount}/>
                </div>
            </div>
        </>}
        </>
    );
}

export default AcademicsBilling;