import { CalendarOutlined } from "@ant-design/icons";
import { Modal, Table } from "antd";
import { sisDateFormat } from "../../../apis/apis";



const BillingPaymentPlan = ({billing_payment_plan_modal,set_billing_payment_plan_modal,payment_plan}) => {

    const billing_payment_plan_table = [

        {
            title: "Payment",
            render: (text, record) => {
                return <><span>{record?.payment_name}</span></>;
            },
        },
        {
            title: "Payment Date",
            render: (text, record) => {
                return <><span><CalendarOutlined/> {sisDateFormat(record?.payment_date)}</span></>;
            },
        },
        {
            title: "Amount",
            render: (text, record) => {
                return <><span>{record?.amount}</span></>;
            },
        },
        {
            title: "Amount Due",
            render: (text, record) => {
                return <><span>{record?.amount_due}</span></>;
            },
        },
        {
            title: "Status",
            render: (text, record) => {
                return <><span>{(record?.paid) === 1 ? "Paid" : "Pending" }</span></>;
            },
        },
    ]

    return(
        <>
        <Modal title={"3 months payment plan"} width={800} open={billing_payment_plan_modal} onCancel={(e) => set_billing_payment_plan_modal(false)} footer={false}>
            <h3 className="first-letter-capitalize" style={{ color: "#999", padding: "20px 0" }}>Payment Dates</h3>
            <Table columns={billing_payment_plan_table} dataSource={payment_plan} pagination={false}/>
        </Modal>
        </>
    );
}

export default BillingPaymentPlan;