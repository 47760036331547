import { Button, Input, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import SisLoader from '../../widgets/loader';
import GradeTableList from './gradeTable';
import SemesterWiseGradeView from './semesterWiseGradeView';
import { GRADES, sisDateFormat } from '../../apis/apis';
import { BACKEND_URL } from '../../config/adminconfig';


const StudentGradesView = () => {

    const [loader, set_loader] = useState(true);
    const [table_list, set_Table_List] = useState([])
    const [cgpa, set_CGPA] = useState("");
    const [confer_date, set_Confer_Date] = useState("");
    const [matriculation_semester, set_Matriculation_Semester] = useState("");
    const [matriculation_date, set_Matriculation_Date] = useState("");
    const [programme_title, set_Programme_Title] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const VIEW_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
        const FORM_DATA = new FormData();
        FORM_DATA.append("student_id", USER_DATA.id);
        FORM_DATA.append("token", USER_DATA.token);
        const EDIT_API_RESPONSE = await GRADES(FORM_DATA);

        if (EDIT_API_RESPONSE?.data?.status) {
            set_CGPA(EDIT_API_RESPONSE?.data?.cgpa);
            set_Confer_Date(EDIT_API_RESPONSE?.data?.enrollment?.confer_date);
            set_Matriculation_Semester(EDIT_API_RESPONSE?.data?.enrollment?.matriculation_semester);
            set_Matriculation_Date(EDIT_API_RESPONSE?.data?.enrollment?.matriculation_date);
            set_Programme_Title(EDIT_API_RESPONSE?.data?.enrollment?.programme_title);
            set_Table_List(EDIT_API_RESPONSE?.data?.tables);
            setFilteredData(EDIT_API_RESPONSE?.data?.tables);
            set_loader(false)
        }
    };
    useEffect(() => {
        VIEW_API();
    }, []);

    const SEARCH_API = async (query) => {
        const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
        const FORM_DATA = new FormData();
        FORM_DATA.append("student_id", USER_DATA.id);
        FORM_DATA.append("search_key", query);
        FORM_DATA.append("token", USER_DATA.token);
        const EDIT_API_RESPONSE = await GRADES(FORM_DATA);
        if (EDIT_API_RESPONSE?.data?.status) {
            set_Table_List(EDIT_API_RESPONSE?.data?.tables);
            set_loader(false)
        }
    };

    //    const handleSearch = (e) => {
    //     const query = e.target.value;
    //     setSearchQuery(query);
    //     if(query == "")
    //         {
    //             SEARCH_API('');
    //         }
    //         else{
    //             SEARCH_API(query);
    //         }

    //   };
    return (
        <>
            {loader ? <SisLoader /> : <>

                <div>
                    <div className='theme-content-head'>
                        <div className='theme-content-left-head'>
                            <h3 className='first-letter-capitalize'>My Grades</h3>
                        </div>
                        <div className='theme-content-right-head'>
                        <Tooltip title="Download Unofficial Transcript PDF">
                        <Button type='primary' onClick={() => window.open(BACKEND_URL + '/unofficial-transcript-pdf/' + JSON.parse(localStorage.getItem('sis_user_data')).token + '/' +  JSON.parse(localStorage.getItem('sis_user_data')).id)} ghost>Generate Unofficial Transcript</Button>
                        </Tooltip>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <label className='first-letter-capitalize'>
                                Program/Degree
                            </label>
                            <br />
                        </div>
                        <div className="col-2">
                            <label className='first-letter-capitalize'>
                                Matriculation Date
                            </label>
                            <br />
                        </div>
                        <div className="col-2">
                            <label className='first-letter-capitalize'>
                                Matriculation Semester
                            </label>
                            <br />
                        </div>
                        <div className="col-2"
                        >
                            <label className='first-letter-capitalize' >
                                Confer Date
                            </label>

                            <br />
                        </div>
                        <Tooltip
                            color="#fff"
                            placement="bottom"
                            title={<div><h3 className='first-letter-capitalize'>Cumulative GPA</h3>
                                <p>A CGPA is your academic performance calculated as an average of your GPA from all completed terms/semesters and is used for an assessment of your overall academic standings.</p>
                            </div>}>
                            <div className="col-2">
                                <label className='first-letter-capitalize'>Cumulative GPA(CGPA)</label>
                                <br />
                            </div>
                        </Tooltip>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-3">
                            <h4>{programme_title ? programme_title : 'N/A'}</h4>
                            <br />
                        </div>
                        <div className="col-2">
                            <h3>{matriculation_date ? sisDateFormat(matriculation_date) : 'N/A'}</h3>
                            <br />
                        </div>
                        <div className="col-2">
                            <h3>{matriculation_semester ? matriculation_semester : 'N/A'}</h3>
                            {/* <br /> */}
                        </div>
                        <div className="col-2">
                            <h3>{confer_date ? sisDateFormat(confer_date) : 'N/A'}</h3>
                            <br />
                        </div>
                        <div className="col-2">
                            <h3>{cgpa?.toFixed(2)}</h3>
                            <br />
                        </div>
                    </div>
                    <hr />
                    {/* <br></br> */}
                    {/* <div className='theme-content-head'>
                        <div className='input-box'>
                            <label className='first-letter-capitalize' style={{ fontSize: "15px", paddingBottom: "5px" }}>Search Course/Course Code</label>
                            <Input
                                style={{ width: "200px", marginLeft: "20px" }}
                                type="text"
                                placeholder="Search..."
                            // value={searchQuery}
                            // onChange={handleSearch}
                            />
                        </div>
                    </div> <br></br>
                    <hr /> */}
                </div>
                {table_list?.length > 0 ? <>
                    {table_list?.map((item) => (
                        <>
                            <br />
                            <SemesterWiseGradeView key={item} item={item} />
                            <br />
                        </>
                    ))}
                </> : <>
                    <p style={{ margin: "50px 0", textAlign: "center", color: "red", fontSize: "14px" }}>Student Grades Data Empty.</p>
                </>

                }
            </>}

        </>
    )
}

export default StudentGradesView