import { SmileOutlined } from "@ant-design/icons";
import { Checkbox, notification, Table } from "antd";
import { REMOVE_COURSE_IN_SEMESTER, SELECTED_COURSE_IN_SEMESTER } from "../../../apis/apis";
import { useState } from "react";



const Concentration = ({student_semester_registration_course_listing, set_list_api_refresh}) => {

  const currentTimeInMillis = Date.now();
  const[calendar_id, set_calendar_id] = useState(student_semester_registration_course_listing?.calendar?.id);
  const[semester_id, set_semester_id] = useState(student_semester_registration_course_listing?.semester?.id);
  const[student_id, set_student_id] = useState(student_semester_registration_course_listing?.basic_information?.id);

  const ADD_SELECTED_COURSE_IN_SEMESTER = async(
    calendar_id,
    semester_id,
    student_id,
    course_id,
    section_id,
    sub_section_id,
    class_section_id,
    room_id,
    timing,
    faculty,
    given_credits,
    course_status
  ) => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("student_id", student_id);
    FORM_DATA.append("calendar_id", calendar_id);
    FORM_DATA.append("semester_id", semester_id);
    FORM_DATA.append("course_id", course_id);
    FORM_DATA.append("section_id", ((section_id === null) || (section_id === undefined)) ? "" : section_id);
    FORM_DATA.append("sub_section_id", ((sub_section_id === null) || (sub_section_id === undefined)) ? "" : sub_section_id);
    FORM_DATA.append("class_section_id",((class_section_id === null) || (class_section_id === undefined)) ? "" : class_section_id);
    FORM_DATA.append("room_id", room_id);
    FORM_DATA.append("timing",  timing);
    FORM_DATA.append("faculty",  faculty);
    FORM_DATA.append("given_credits", given_credits);
    FORM_DATA.append("course_status", course_status);
    const API_RESPONSE = await SELECTED_COURSE_IN_SEMESTER(FORM_DATA);
    if (API_RESPONSE.data.status) {
      notification.open({
        message: "Success!!",
        description: "You have successfully registered the course!",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      set_list_api_refresh(currentTimeInMillis)
    } 
    else {
      notification.open({
        message: "Note!!",
        description: "You have already registered in the course!",
        icon: <SmileOutlined style={{ color: "yellow" }} />,
      });
      
    }

    
  }
  const REMOVE_API = async (
    id,
   ) => {
    //  set_loader(true);
 
     const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
     const FORM_DATA = new FormData();
     FORM_DATA.append("token", USER_DATA.token);
     FORM_DATA.append("id", id);
     const API_RESPONSE = await REMOVE_COURSE_IN_SEMESTER(FORM_DATA);
 
     if (API_RESPONSE?.data?.status) {
       notification.open({
         message: "Success!!",
         description: "You have successfully removed the course!",
         icon: <SmileOutlined style={{ color: "green" }} />,
       });
       set_list_api_refresh(currentTimeInMillis)
     }
   };


    const concentartion_course_column = [
      {
        title: "Courses",
        render: (text,record) => {

          return (
            <>
              {record.course_semester_registration ? (
                <>
                  <label style={{ position: "relative", course: "pointer" }}>
                    {record.course_semester_registration.course_status > 2 ? (
                      <>
                        <Checkbox
                          disabled
                          style={{
                            position: "absolute",
                            top: "0px",
                            left: "0px",
                          }}
                          checked
                        />
                      </>
                    ) : (
                      <>
                        <Checkbox
                          checked={record.checked}
                          onChange={(e) =>
                            REMOVE_API(
                              record?.course_semester_registration?.id,
                              
                            )
                          }
                          style={{
                            position: "absolute",
                            top: "0px",
                            left: "0px",
                          }}
                        />
                      </>
                    )}
  
                    <div style={{ minWidth: "350px" }}>
                      <span
                        style={{
                          fontSize: "12px",
                          paddingLeft: "22px",
                          course: "pointer",
                        }}
                      >
                        {" "}
                        {record.title}{" "}
                        <span
                          style={{
                            fontSize: "10px",
                            backgroundColor: "red",
                            color: "#fff",
                            padding: "1px 7px",
                            borderRadius: "5px",
                          }}
                        >
                          SEC-{record.section_name}
                        </span>
                        {record?.transferred_course && (
                          <>
                            <i
                              title="Transferred course"
                              style={{
                                background: "black",
                                marginLeft: "10px",
                                padding: "2px 7px",
                                color: "#fff",
                                borderRadius: "50px",
                              }}
                            >
                              T
                            </i>
                          </>
                        )}
                      </span>
                    </div>
                  </label>
                </>
              ) : (
                <>
                  <label style={{ position: "relative", course: "pointer" }}>
                    {record.rooms && record?.timing && record?.faculty ? (
                      <>
                        <Checkbox
                          onChange={(e) =>
                            ADD_SELECTED_COURSE_IN_SEMESTER(
                              calendar_id,
                              semester_id,
                              student_id,
                              record.id,
                              record.section_id,
                              record.sub_section_id,
                              record?.children_id,
                              record?.rooms?.id,
                              record?.timing,
                              record?.faculty?.id,
                              1,
                              record?.transferred?.credit_earn === record?.units ? 3 : record?.transferred?.credit_earn < record?.units ? 7 : 1
                            )
                          }
                          style={{
                            position: "absolute",
                            top: "0px",
                            left: "0px",
                          }}
                        />
                      </>
                    ) : (
                      <></>
                    )}
  
                    <div style={{ minWidth: "350px" }}>
                      <span
                        style={{
                          fontSize: "12px",
                          paddingLeft: "22px",
                          course: "pointer",
                        }}
                      >
                        {" "}
                        {record.title}{" "}
                        <span
                          style={{
                            fontSize: "10px",
                            backgroundColor: "red",
                            color: "#fff",
                            padding: "1px 7px",
                            borderRadius: "5px",
                          }}
                        >
                          SEC-{record.section_name}
                        </span>
                        {record?.transferred_course && (
                          <>
                            <i
                              title="Transferred course"
                              style={{
                                background: "black",
                                marginLeft: "10px",
                                padding: "2px 7px",
                                color: "#fff",
                                borderRadius: "50px",
                              }}
                            >
                              T
                            </i>
                          </>
                        )}
                      </span>
                    </div>
                  </label>
                </>
              )}
            </>
          );
            
        },
    },
        {
            title: "Course Code",
            render: (text, record) => {
                return <><span>{record?.code}</span></>;
            },
        },

        {
            title: "Total Credits",
            render: (text, record) => {
                return <><span >{record?.units}</span></>;
            },
        },
        {
            title: "Given Credits",
            render: (text, record) => {
                // return <><span >{record?.credit_earn.toFixed(1)}</span></>;
            },
        },
        {
            title: "Room",
            render: (text, record) => {
                return <>
                    <span>{record?.rooms?.room_name}</span>
                </>;
            },
        },
        {
            title: "Timing",
            render: (text, record) => {
                var timingData = record?.timing ? JSON.parse(record.timing) : null;
                return<>
                {timingData && get_timing(record, timingData)}</>;
            },
        },
        {
            title: "Faculty",
            render: (text, record) => {
                return <>
                    <span style={{textTransform:"capitalize"}}>{record?.faculty?.honorific}{" "}{record?.faculty?.name}</span>
                </>;
            },
        },
        {
            title: "Course Status",
            render: (text, record) => {
                return <>
                    <span>{(record?.schedule_status === null) ? "Not Registered" : ""}</span>
                </>;
            },
        },
       
    ];
    const get_timing = (data, selected_days) => {
        return (
          <>
            {selected_days.map((item) => (
              <>
                {item?.start_time && (
                  <>
                    {data.rooms && data?.timing && data?.faculty ? (
                      <>
                        <span
                          style={{
                            width: "110px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "13px",
                          }}
                        >
                          {item?.value?.slice(0, 2).toUpperCase()}{" "}
                          {item?.start_time} - {item?.end_time}
                        </span>
                        <br />
                      </>
                    ) : (
                      <>
                        <span style={{ color: "red" }}>Not Available</span>
                      </>
                    )}
                  </>
                )}
              </>
            ))}
          </>
        );
      };
      
    return (
        <>
            <h3 className='first-letter-capitalize' style={{ color: "#3B82F6" }}>Concentration</h3>
            <br/>
            <Table columns={concentartion_course_column} dataSource={student_semester_registration_course_listing?.progrma_concentration} pagination={false}/>
        </>
    )
}

export default Concentration;