import { CalendarOutlined } from "@ant-design/icons";
import { Button, Modal, Table } from "antd";
import { sisDateFormat } from "../../../apis/apis";
import { useEffect, useState } from "react";
import AcademicsStripePayment from "../Billing/Payment/academics_stripe_payment";

const AcademicPaymentPlan = ({
  academics_payment_plan_modal,
  set_academics_payment_plan_modal,
  payment_plan,
}) => {
  const [showTable, setShowTable] = useState(true);
  const [plan_id, set_plan_id] = useState("");
  const [payment_indent_json, set_payment_indent_json] = useState("");
  const [amount, set_amount] = useState("");

  useEffect(() => {
    if (plan_id && payment_indent_json && amount) {
        setShowTable(false);
    }
}, [plan_id, payment_indent_json, amount]);


  const academics_payment_plan_table = [
    {
      title: "Payment",
      render: (text, record) => {
        return (
          <>
            <span>{record?.payment_name}</span>
          </>
        );
      },
    },
    {
      title: "Payment Date",
      render: (text, record) => {
        return (
          <>
            <span>
              <CalendarOutlined /> {sisDateFormat(record?.payment_date)}
            </span>
          </>
        );
      },
    },
    {
      title: "Amount",
      render: (text, record) => {
        return (
          <>
            <span>{record?.amount}</span>
          </>
        );
      },
    },
    {
      title: "Amount Due",
      render: (text, record) => {
        return (
          <>
            <span>{record?.amount_due}</span>
          </>
        );
      },
    },
    {
      title: "Status",
      render: (text, record) => {
        return (
          <>
            <span>{record?.paid === 1 ? "Paid" : "Pending"}</span>
          </>
        );
      },
    },
    {
      title: "Action",
      render: (text, record) => {
        return (
          <>
            {" "}
            <span>
              <div className="theme-content-left-head">
               
                {record?.paid ? (
                  <>
                  
                    <Button type="primary" style={{ height: "40px" }} disabled={true}>
                      Paid
                    </Button>
                  </>
                ) : (
                  <>
                  
                    <Button
                      type="primary"
                      style={{ height: "40px" }}
                      onClick={() => {
                        set_plan_id(record?.id);
                        set_payment_indent_json(record?.payment_intent_json);
                        set_amount(record?.amount);
                      }}
                    >
                      Pay Now
                    </Button>
                  </>
                )}
              </div>
            </span>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Modal
        title={"3 months payment plan"}
        width={800}
        open={academics_payment_plan_modal}
        onCancel={(e) => {
          window.location.reload();
         // set_academics_payment_plan_modal(false);
          // setShowTable(true);
        }}
        footer={false}
      >
        <h3
          className="first-letter-capitalize"
          style={{ color: "#999", padding: "20px 0" }}
        >
          Payment Dates
        </h3>
        {showTable ? (
          <Table
            columns={academics_payment_plan_table}
            dataSource={payment_plan}
            pagination={false}
          />
        ) : (
            <>
             {plan_id &&   <AcademicsStripePayment
            plan_id={plan_id}
            upfront_amount_payment_indent_json={payment_indent_json}
            amount={amount}
            type={null}
          /> }
            </>
        )}
      </Modal>
    </>
  );
};

export default AcademicPaymentPlan;
