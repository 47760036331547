import {Layout, Menu, Button, theme} from 'antd';
import { Content, Header, Footer } from 'antd/es/layout/layout';
import logoTheme from '../../../images/fav.png';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { LogoutOutlined } from '@ant-design/icons';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CustomHeader from '../Header-and-Footer/customHeader';
import CreateAccount from '../Create-Account/createAccount';
import CustomFooter from '../Header-and-Footer/customFooter';

const SelfRegistrationLayout = () => {
   
    return(
        <>
        <CustomHeader/>
        <CreateAccount/>
        <CustomFooter/>
        </>
    );
}

export default SelfRegistrationLayout;