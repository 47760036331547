import React, { useEffect, useState } from 'react';
import { Table, Input, Avatar } from 'antd';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { ADMIN_STUDENTS_LISTING, SIS_STORAGE } from '../../../apis/apis';
import SisLoader from '../../../widgets/loader';


const Studentstally = () => {
  const navigate = useNavigate();
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState(null);

  const [loader, set_loader] = useState(true);
  const [errors, set_errors] = useState([]);
  // Form State
  const [table_list, set_table_list] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);





  const columns = [
    {
      title:'Name',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => navigate('/student-detail/'+btoa(record.id))}>
         <img
                                  src={SIS_STORAGE + "/enrollments/" + record.enrollment_image}
                                  frameborder="0"
                                  style={{ width: "30px", height: "30px", borderRadius:"30px", marginRight: "10px"}}
                                ></img>
          {record.enrollment_first_name} {record.enrollment_middle_name !== 'null' && record.enrollment_middle_name && record.enrollment_middle_name} {record.enrollment_last_name}
        </div>
      ),
  
    },
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            Programme
          </div>
          {sortColumn === 'programme' && sortOrder === 'asc' && <UpOutlined />}
          {sortColumn === 'programme' && sortOrder === 'desc' && <DownOutlined />}
        </div>
      ),
      dataIndex: 'programme_title',
      key: 'programme_title',
    },
    {
      title: 'Programme status',
      dataIndex: 'programme_status',
      key: 'programme_status',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => navigate('/student-detail/'+btoa(record.id))}>
          {text}
        </div>
      ),
    },
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            Action
          </div>
        </div>
      ),
      dataIndex: 'dot',
      key: 'dot',
    },
  ];

  
  // DB list
  const LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
    const FORM_DATA = new FormData();
    FORM_DATA.append('token', USER_DATA.token);
    const API_RESPONSE = await ADMIN_STUDENTS_LISTING(FORM_DATA);
    if (API_RESPONSE.data.status) {
        set_table_list(API_RESPONSE.data.enrollments_students);
        setFilteredData(API_RESPONSE.data.enrollments_students);
        set_loader(false);
    } else {
        set_loader(false);
    }
}


useEffect(() => {
    LIST_API();
}, [loader]);


  // Search
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    const filtered = table_list.filter(
      (item) =>
        item?.enrollment_first_name.toLowerCase().includes(query.toLowerCase()) || item?.programme_title.toLowerCase().includes(query.toLowerCase()) || item?.programme_status.toLowerCase().includes(query.toLowerCase()) || item?.enrollment_last_name.toLowerCase().includes(query.toLowerCase())
    );
    if (query === "") {
      setFilteredData(table_list);
    } else {
      setFilteredData(filtered);
    }
  };
  return (
    <div>
      <div className="theme-content-head">
        <div className="theme-content-left-head">
          <h3>Students</h3>
        </div>
      </div>

      <div className="theme-content-head">
        <div className="input-box">
          <Input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => handleSearch(e)}
          />
          {errors?.code && (
            <span style={{ color: "red" }}>{errors?.code[0]}</span>
          )}
        </div>
      </div>
      {loader ? <SisLoader /> : <Table columns={columns} dataSource={filteredData}/>}
      
    </div>
  );
};
export default Studentstally;




