
export const unit_selection_limit = (selected_semester_units, semester_registration, basic_information, students_programmes_registrations, program) => {


    const admission_status = semester_registration?.admission_status //full time student
    const sct_status = semester_registration?.sct_status?.toLowerCase()  // Domestic students
    const entering_classification = students_programmes_registrations?.entering_classification?.toLowerCase() // Graduate level
    const programme_code = program?.programme_code?.toLowerCase()

 //  DBA student can attempt maximum of 18 units per semester.
    if (programme_code == 'dba') {
        if (selected_semester_units > 18) {
            return (
                {
                    'status': false,
                    'message': 'DBA student can attempt maximum of 18 units per semester.'
                }
            )
        } else {
            return (
                {
                    'status': true,
                }
            )
        }
    }else{
        if(entering_classification == 'graduate'){
            if (sct_status == 'domestic') {
                if (admission_status.toLowerCase() == 'full time') {
                    if (selected_semester_units < 6) {
                        return (
                            {
                                'status': false,
                                'message': 'Domestic students for Graduate Level Course has to register at least 6 units to be considered as full time student'
                            }
                        )
                    } else {
                        return (
                            {
                                'status': true,
                            }
                        )
                    }
                }else{
                    if (selected_semester_units > 18) {
                        return (
                            {
                                'status': false,
                                'message': 'Graduate Student (MBA/MS-FM/MS-IB) student can attempt maximum of 18 units per semester.'
                            }
                        )
                    } else {
                        return (
                            {
                                'status': true,
                            }
                        )
                    }
                }
              
            }else{
                if (admission_status.toLowerCase() == 'full time') {
                    if (selected_semester_units < 9) {
                        return (
                            {
                                'status': false,
                                'message': 'International student for Graduate Level Course has to register minimum of 9 credit units as a full time student'
                            }
                        )
                    } else {
                        return (
                            {
                                'status': true,
                            }
                        )
                    }
                }else{
                    if (selected_semester_units > 18) {
                        return (
                            {
                                'status': false,
                                'message': 'Graduate Student (MBA/MS-FM/MS-IB) student can attempt maximum of 18 units per semester.'
                            }
                        )
                    } else {
                        return (
                            {
                                'status': true,
                            }
                        )
                    }
                }
            
            }     
        }else{
            if (sct_status == 'domestic') {
                if (selected_semester_units < 9) {
                    return (
                        {
                            'status': false,
                            'message': 'Domestic students for Graduate Level Course has to register at least 6 units to be considered as full time student'
                        }
                    )
                } else {
                    return (
                        {
                            'status': true,
                        }
                    )
                }
               
            }else{
                if (admission_status.toLowerCase() == 'full time') {
                    if (selected_semester_units < 12) {
                        return (
                            {
                                'status': false,
                                'message': 'International Undergraduate student, they must register at least 12 units to be considered as a full time student'
                            }
                        )
                    } else {
                        if (selected_semester_units > 24) {
                            return (
                                {
                                    'status': false,
                                    'message': 'A full time undergraduate student can attempt maximum of 24 units per semester.'
                                }
                            )
                        } else {
                            return (
                                {
                                    'status': true,
                                }
                            )
                        }
                    }
                 
                }else{
                    if (admission_status.toLowerCase() == 'full time') {
                        if (selected_semester_units > 24) {
                            return (
                                {
                                    'status': false,
                                    'message': 'A full time undergraduate student can attempt maximum of 24 units per semester.'
                                }
                            )
                        } else {
                            return (
                                {
                                    'status': true,
                                }
                            )
                        }
                    }else{
                        return (
                            {
                                'status': true,
                            }
                        )
                    }
                }
             
            }

          
        }
    }
}