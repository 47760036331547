import { Table } from "antd";
import { useEffect, useState } from "react";
import { Await } from "react-router-dom";
import { bILLING_TABLE } from "../../../apis/apis";


const AcademicsViewBillingCourseTable = ({tuition_fees}) => {

    const academics_view_billing_course_table = [

        {
            title: "Course Name",
            dataIndex: "courseName",
            key: "courseName",
            width: "20%",
            render: (text, record) => {
              return <> {record.title} </>;
            },
          },
          {
            title: "Course Code",
            dataIndex: "courseCode",
            key: "courseCode",
            width: "20%",
            render: (text, record) => {
              return <> {record.code} </>;
            },
          },
          {
            title: "Attempting Credits",
            dataIndex: "credit",
            key: "credit",
            width: "20%",
            render: (text, record) => {
              return <> {record.given_credits} </>;
            },
          },
          {
            title: "Cost per Unit",
            dataIndex: "unit",
            key: "unit",
            width: "20%",
            render: (text, record) => {
              return <> {record?.cost_per_unit} </>;
            },
          },
          {
            title: "Total Cost",
            dataIndex: "totalCost",
            key: "totalCost",
            width: "20%",
      
            render: (text, record) => {
              return <> {record?.total_cost} </>;
            },
          },
    ]

    return (
        <>
           <Table columns={academics_view_billing_course_table} dataSource={tuition_fees} pagination={false}/>
        </>
    );
}

export default AcademicsViewBillingCourseTable;