
import { useNavigate } from 'react-router-dom';
import logoTheme from '../../../images/fav.png';
import './customHeader.css'
const CustomHeader = () => {
    const navigate = useNavigate();
    return(
        <>
            <div className="row"  style={{backgroundColor:"#900000"}}>
                <div className="col-4">
                    <div style={{display:"flex", padding:"10px", alignItems:'center'}}>
                    <img className='theme-logo' src={logoTheme} alt="Lincoln University" />
                    <h2 style={{color:"#fff"}}>Enrollment Application</h2>
                    </div>
                </div>
                <div className='col-5' style={{display: "flex", justifyContent: "center", alignItems: "center", float:"right"}}>
            <ul style={{listStyle: "none", padding: "35px 0", margin: 0, display: "flex", justifyContent: "center"}}>
            <li style={{marginRight: "40px", color: "#DFA414", fontWeight:"500", fontSize:"18px"}}>Create Account</li>
            <li style={{color: "#fff", fontWeight:"500", fontSize:"18px", cursor:"pointer"}} onClick={()=> window.location= "https://uat-students.icore.uk/"}>Login</li>
        </ul>
    </div>
            </div>
        </>
    );
}

export default CustomHeader;