import React, { useEffect, useRef, useState } from "react";
import { Button, Checkbox, DatePicker, Input, Radio, Select, Spin, Table, Tooltip, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { CloseOutlined, CloudUploadOutlined, EditOutlined, LoadingOutlined, SmileOutlined, } from "@ant-design/icons";
import { SIS_STORAGE, PERSONAL_INFORMATION_SAVE, GET_PERSONAL_INFORMATION, sisDateFormat } from "../../../../apis/apis";
import { CountryDropdown } from "react-country-region-selector";
import '../processbar.css'
import dayjs from "dayjs";
import SisLoader from "../../../../widgets/loader";
import user_icon from '../../../../images/user.png';

const PersonalInformationPreview = () => {

    const dateFormat = 'MM-DD-YYYY';
    const navigate = useNavigate();
    const [loader, set_loader] = useState(true);
    const [errors, set_errors] = useState([]);
    const studentImage = useRef(null);
    // Form State
    const [application_start_on, set_application_start_on] = useState("");
    const [application_no, set_application_no] = useState("");

    // basic information
    const [image, set_image] = useState("");
    const [first_name, set_first_name] = useState("");
    const [middle_name, set_middle_name] = useState("");
    const [last_name, set_last_name] = useState("");

    const [student_id, set_student_id] = useState("");
    const [gender, set_gender] = useState("");
    const [student_athlete, set_student_athlete] = useState("");
    const [birth_date, set_birth_date] = useState("");
    const [country_of_birth, set_country_of_birth] = useState("");
    const [city_of_birth, set_city_of_birth] = useState("");
    const [student_category, set_student_category] = useState("");
    const [ssn, set_ssn] = useState("");
    const [ssnerror, set_Ssn_Error] = useState('');
    const [programme_list, set_programme_list] = useState([]);
    const [school_decision, set_school_decision] = useState("");
    const [application_checklist, set_application_checklist] = useState("");

    // Contact
    const [phoneerror, set_Phone_Error] = useState('');
    const [emergencyphoneerror, set_emergency_phone_error] = useState('');
    const [phone, set_phone] = useState("");
    const [primary_email, set_primary_email] = useState("");
    const [secondary_email, set_secondary_email] = useState("");
    const [citizenship, set_citizenship] = useState("");
    const [emergency_first_name, set_emergency_first_name] = useState("");
    const [emergency_last_name, set_emergency_last_name] = useState("");
    const [emergency_phone, set_emergency_phone] = useState("");
    const [emergency_email, set_emergency_email] = useState("");
    const [emergency_relationship, set_emergency_relationship] = useState("");
    const [emergency_address1, set_emergency_address1] = useState("");
    const [emergency_address2, set_emergency_address2] = useState("");
    const [emergency_address_city, set_emergency_address_city] = useState("");
    const [emergency_address_state, set_emergency_address_state] = useState("");
    const [emergency_address_postal, set_emergency_address_postal] = useState("");
    const [emergency_address_country, set_emergency_address_country] = useState("");
    const [mailing_address1, set_mailing_address1] = useState("");
    const [mailing_address2, set_mailing_address2] = useState("");
    const [mailing_address_city, set_mailing_address_city] = useState("");
    const [mailing_address_state, set_mailing_address_state] = useState("");
    const [mailing_address_postal, set_mailing_address_postal] = useState("");
    const [mailing_address_country, set_mailing_address_country] = useState("");
    const [home_country_address1, set_home_country_address1] = useState("");
    const [home_country_address2, set_home_country_address2] = useState("");
    const [home_country_address_city, set_home_country_address_city] =
        useState("");
    const [home_country_address_state, set_home_country_address_state] =
        useState("");
    const [home_country_address_postal, set_home_country_address_postal] =
        useState("");
    const [home_country_address_country, set_home_country_address_country] =
        useState("");

    const [api_image, set_api_image] = useState("");

    useEffect(() => {
        VIEW_PERSONAL_INFORMATION();
    }, []);

    const VIEW_PERSONAL_INFORMATION = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('student_user_id', USER_DATA.id);
        const VIEW_PERSONAL_INFORMATION_API_RESPONSE = await GET_PERSONAL_INFORMATION(FORM_DATA);
        if (VIEW_PERSONAL_INFORMATION_API_RESPONSE?.data?.status) {
            const PERSONAL_DETAILS_DATA = VIEW_PERSONAL_INFORMATION_API_RESPONSE?.data?.data?.personal_details;
            const PERSONAL_CONTACT_DETAILS_DATA = VIEW_PERSONAL_INFORMATION_API_RESPONSE?.data?.data?.contact_details;
            set_application_checklist(PERSONAL_DETAILS_DATA?.application_checklist);
            set_school_decision(PERSONAL_DETAILS_DATA?.school_decision);
            set_application_no(PERSONAL_DETAILS_DATA?.application_no);
            set_application_start_on(PERSONAL_DETAILS_DATA?.application_start_date);
            set_api_image(PERSONAL_DETAILS_DATA?.image);
            set_first_name(PERSONAL_DETAILS_DATA?.first_name);
            set_middle_name(PERSONAL_DETAILS_DATA?.middle_name);
            set_last_name(PERSONAL_DETAILS_DATA?.last_name);
            set_student_id(PERSONAL_DETAILS_DATA?.student_id);
            set_birth_date(PERSONAL_DETAILS_DATA?.birth_date);
            set_country_of_birth(PERSONAL_DETAILS_DATA?.country_of_birth);
            set_city_of_birth(PERSONAL_DETAILS_DATA?.city_of_birth);
            set_gender(PERSONAL_DETAILS_DATA?.gender);
            set_citizenship(PERSONAL_DETAILS_DATA?.citizenship);
            set_student_athlete(PERSONAL_DETAILS_DATA?.student_athlete);
            set_ssn(PERSONAL_DETAILS_DATA?.ssn);
            set_primary_email(PERSONAL_CONTACT_DETAILS_DATA?.primary_email);
            set_secondary_email(PERSONAL_CONTACT_DETAILS_DATA?.secondary_email);
            set_phone(PERSONAL_CONTACT_DETAILS_DATA?.phone);
            set_emergency_first_name(PERSONAL_CONTACT_DETAILS_DATA?.emergency_contact?.emergency_first_name);
            set_emergency_last_name(PERSONAL_CONTACT_DETAILS_DATA?.emergency_contact?.emergency_last_name);
            set_emergency_email(PERSONAL_CONTACT_DETAILS_DATA?.emergency_contact?.emergency_email);
            set_emergency_relationship(PERSONAL_CONTACT_DETAILS_DATA?.emergency_contact?.emergency_relationship);
            set_emergency_phone(PERSONAL_CONTACT_DETAILS_DATA?.emergency_contact?.emergency_phone);
            var emergency_address = PERSONAL_CONTACT_DETAILS_DATA?.emergency_address;
            set_emergency_address_country(emergency_address?.emergency_address_country);
            set_emergency_address_city(emergency_address?.emergency_address_city);
            set_emergency_address_state(emergency_address?.emergency_address_state);
            set_emergency_address_postal(emergency_address?.emergency_address_postal);
            set_emergency_address1(emergency_address?.emergency_address1);
            set_emergency_address2(emergency_address?.emergency_address2);
            var mailing_address = PERSONAL_CONTACT_DETAILS_DATA?.mailing_address;
            set_mailing_address_city(mailing_address?.mailing_address_city);
            set_mailing_address_state(mailing_address?.mailing_address_state);
            set_mailing_address_postal(mailing_address?.mailing_address_postal);
            set_mailing_address_country(mailing_address?.mailing_address_country);
            set_mailing_address1(mailing_address?.mailing_address1);
            set_mailing_address2(mailing_address?.mailing_address2);
            var home_country_address = PERSONAL_CONTACT_DETAILS_DATA?.home_country_address;
            set_home_country_address_city(home_country_address?.home_country_address_city);
            set_home_country_address_state(home_country_address?.home_country_address_state);
            set_home_country_address_postal(home_country_address?.home_country_address_postal);
            set_home_country_address_country(home_country_address?.home_country_address_country);
            set_home_country_address1(home_country_address?.home_country_address1);
            set_home_country_address2(home_country_address?.home_country_address2);
            set_loader(false);
        }
        else {
            set_loader(false);
        }
    }


    return (
        <>
            {loader ? <>
                <SisLoader />
            </>
                :
                <>
                    {localStorage.getItem("payment_status") == 1 &&
                        <>
                            <br />
                            <hr />
                            <br />
                            <div className="row" style={{ marginLeft: 0 }}>
                                <div className="theme-content-left-head">
                                    <h2>Online Application</h2>
                                    <br />
                                    <div className="attendance-summary" style={{ width: "300px" }}>
                                        <div className="attendance-item" style={{ width: "100%" }}>
                                            <div className="attendance-label first-letter-capitalize">
                                                <h2 style={{ color: "#626262" }}>Application Status</h2>
                                            </div>
                                            <div className="attendance-value">
                                                <h3>{(school_decision === "") || (school_decision === "null") ? "Pending" : school_decision}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    <div>
                        <>
                            <div>
                                <br />
                                <hr />
                                {localStorage.getItem("payment_status") == 0 &&
                                    <div className="row">
                                        <div className="col-2" style={{ float: "right" }}>
                                            <div className="input-box" style={{ float: "right" }}>
                                                <Button
                                                    type="primary"
                                                    icon={<EditOutlined />}
                                                    onClick={() => navigate("/")}>
                                                    Edit
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="theme-content-left-head">
                                    <h2 style={{ color: "#626262" }}>Basic Information</h2>
                                </div>
                                <br />
                            </div>
                            <>
                                <div className="row">
                                    <div className="col-3">
                                        <div className="input-box" style={{ position: "relative", marginTop: 0 }}>
                                            {/* <label>Image</label>
                                    <br></br> */}
                                            <div className="image-box">
                                                <img
                                                    src={SIS_STORAGE + "/enrollments/" + api_image}
                                                    frameBorder="0"
                                                    style={{ width: "100%", height: "150px" }}
                                                ></img>
                                            </div>
                                            {errors?.image && (
                                                <>
                                                    <span style={{ color: "red" }}>{errors?.image[0]}</span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-3 first-letter-capitalize">
                                        <p>Application number</p>
                                        <h4 style={{ marginTop: "6px" }}>{application_no}</h4>
                                    </div>
                                    <div className="col-3 first-letter-capitalize">
                                        <p>Application Started On</p>
                                        <h4 style={{ marginTop: "6px" }}>{sisDateFormat(application_start_on)}</h4>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    {/* <div className="col-3 first-letter-capitalize">
                                        <p>Student Id</p>
                                        <h4 style={{ marginTop: "6px" }}>{student_id}</h4>
                                    </div> */}
                                    <div className="col-3 first-letter-capitalize">
                                        <p>First Name</p>
                                        <h4 style={{ marginTop: "6px" }}>{first_name}</h4>
                                    </div>
                                    <div className="col-3 first-letter-capitalize">
                                        <p>Middle Name</p>
                                        <h4 style={{ marginTop: "6px" }}>{middle_name}</h4>
                                    </div>
                                    <div className="col-3 first-letter-capitalize">
                                        <p>Last Name</p>
                                        <h4 style={{ marginTop: "6px" }}>{last_name}</h4>
                                    </div>
                                    <div className="col-3 first-letter-capitalize">
                                        <p>Date of Birth</p>
                                        <h4 style={{ marginTop: "6px" }}>{sisDateFormat(birth_date)}</h4>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <div className="col-3 first-letter-capitalize">
                                        <p>Country of Birth</p>
                                        <h4 style={{ marginTop: "6px" }}>{country_of_birth}</h4>
                                    </div>
                                    <div className="col-3 first-letter-capitalize">
                                        <p>Birth City</p>
                                        <h4 style={{ marginTop: "6px" }}>{city_of_birth}</h4>
                                    </div>
                                    <div className="col-3 first-letter-capitalize">
                                        <p>Citizenship</p>
                                        <h4 style={{ marginTop: "6px" }}>{citizenship}</h4>
                                    </div>
                                    <div className="col-3 first-letter-capitalize">
                                        <p>Gender</p>
                                        <h4 style={{ marginTop: "6px" }}>
                                            {gender === "male" && "Male"}
                                            {gender === "female" && "Female"}
                                            {gender === "others" && "Other"}
                                        </h4>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <div className="col-3 first-letter-capitalize">
                                        <p>Are You an Athlete?</p>
                                        <h4 style={{ marginTop: "6px" }}>
                                            {student_athlete === 1 && "Yes"}
                                            {student_athlete === 0 && "No"}
                                        </h4>
                                    </div>
                                    <div className="col-3 first-letter-capitalize">
                                        <p>SSN</p>
                                        <h4 style={{ marginTop: "6px" }}>{ssn}</h4>
                                    </div>
                                    <div className="col-3 first-letter-capitalize">
                                        <p>Phone</p>
                                        <h4 style={{ marginTop: "6px" }}>{phone}</h4>
                                    </div>
                                </div>
                            </>

                            <>
                                <br />
                                <div className="row">
                                    <div className="col-6 first-letter-capitalize">
                                        <p>Primary Email</p>
                                        <h4 style={{ marginTop: "6px" }}>{primary_email}</h4>
                                    </div>
                                    <div className="col-6 first-letter-capitalize">
                                        <p>Secondary Email</p>
                                        <h4 style={{ marginTop: "6px" }}>{secondary_email}</h4>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <div className="col-6 first-letter-capitalize">
                                        <p>Mailing Address</p>
                                        <h4 style={{ marginTop: "6px" }}>{mailing_address1},{" "}{(mailing_address2) === "null" ? "" : mailing_address2},{" "}
                                            {mailing_address_city},{" "}{mailing_address_state},{" "}{mailing_address_country},{" "}{mailing_address_postal}.
                                        </h4>
                                    </div>
                                    <div className="col-6 first-letter-capitalize">
                                        <p>Home Address</p>
                                        <h4 style={{ marginTop: "6px" }}>{home_country_address1},{" "}{(home_country_address2) === "null" ? "" : home_country_address2},{" "}
                                            {home_country_address_city},{" "}{home_country_address_state},{" "}{home_country_address_postal},{" "}{home_country_address_postal}.
                                        </h4>
                                    </div>
                                </div>
                                <br></br>
                                <div className="theme-content-left-head">
                                    <h3 style={{ color: "#626262" }}>Emergency Contact Details</h3>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-3 first-letter-capitalize">
                                        <p>First Name</p>
                                        <h4 style={{ marginTop: "6px" }}>{emergency_first_name}</h4>
                                    </div>
                                    <div className="col-3 first-letter-capitalize">
                                        <p>Last Name</p>
                                        <h4 style={{ marginTop: "6px" }}>{emergency_last_name}</h4>
                                    </div>
                                    <div className="col-3 first-letter-capitalize">
                                        <p>Relationship</p>
                                        <h4 style={{ marginTop: "6px" }}>
                                            {emergency_relationship === "father" && "Father"}
                                            {emergency_relationship === "mother" && "Mother"}
                                            {emergency_relationship === "brother" && "Brother"}
                                            {emergency_relationship === "sister" && "Sister"}
                                            {emergency_relationship === "other" && "Other"}
                                        </h4>
                                    </div>
                                    <div className="col-3 first-letter-capitalize">
                                        <p>Phone</p>
                                        <h4 style={{ marginTop: "6px" }}>{emergency_phone}</h4>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-6 first-letter-capitalize">
                                        <p>Emergency Mailing Address</p>
                                        <h4 style={{ marginTop: "6px" }}>{emergency_address1},{" "}{(emergency_address2) === "null" ? "" : emergency_address2},{" "}
                                            {emergency_address_city},{" "}{emergency_address_state},{" "}{emergency_address_country},{" "}{emergency_address_postal}.
                                        </h4>
                                    </div>
                                    <div className="col-6 first-letter-capitalize">
                                        <p>Email</p>
                                        <h4 style={{ marginTop: "6px" }}>{emergency_email}</h4>
                                    </div>
                                </div>
                                <br />
                            </>
                        </>
                    </div>
                </>
            }
        </>
    );
}

export default PersonalInformationPreview;