import React, { useEffect, useState } from 'react';
import './enrollmentapplicationformlayout.css';
import Sider from 'antd/es/layout/Sider';
import user_icon from '../../../images/user.png';
import { Button, Layout, Menu, theme } from 'antd';
import { DashboardOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { Content, Header } from 'antd/es/layout/layout';
import { Outlet, useNavigate, useLocation, useParams } from 'react-router-dom';
import logoTheme from '../../../images/fav.png';
import LayoutSelfEnrolledStudentName from './layoutSelfEnrolledStudentName';

const EnrollmentApplicationFormLayout = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(false);
    const [selected_menu, set_selected_menu] = useState();
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const [storageData, setStorageData] = useState([]);
    useEffect(() => {
        const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
        setStorageData(USER_DATA);
        const location_pathname = location.pathname;
        let new_selected_menu = [];
    
        switch (location_pathname) {
            case '/':
                new_selected_menu = ['1'];
                break;
            case '/demographic-information':
                new_selected_menu = ['2'];
                break;
            case '/finance-declaration':
                new_selected_menu = ['3'];
                break;
            case '/agreement':
                new_selected_menu = ['4'];
                break;
            case '/formpreview':
                new_selected_menu = ['5'];
                break;
            case '/payment':
                new_selected_menu = ['6'];
                break;
            default:
                break;
        }
    
        if (new_selected_menu.length && new_selected_menu !== selected_menu) {
            set_selected_menu(new_selected_menu);
        }
    
    }, [location]);
    
    // useEffect(() => {
    //     const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    //     setStorageData(USER_DATA);
    //     var location_pathname = window.location.pathname;
    //     if (location_pathname === '/') {
    //         set_selected_menu(['1']);
    //     }
    //     if (location_pathname === '/demographic-information') {
    //         set_selected_menu(['2']);
    //     }
    //     if (location_pathname === '/finance-declaration') {
    //         set_selected_menu(['3']);
    //     }
    //     if (location_pathname === '/agreement') {
    //         set_selected_menu(['4']);
    //     }
    //     if (location_pathname === '/payment') {
    //         set_selected_menu(['5']);
    //     }
    //     if (location_pathname === '/formpreview') {
    //         set_selected_menu(['6']);
    //     }
      
    // })
    return (
        <>
            <Layout>
                <Sider
                    style={{ backgroundColor: " #900000" }}
                    trigger={null} collapsible collapsed={collapsed}>
                    <div className='logo-user-box'>
                        <div className='logo-area'>
                            <img className='theme-logo' src={logoTheme} />
                        </div>
                        {!collapsed && <>
                            <h2>Lincoln University</h2>
                            <p>Enrollment Application</p>
                            <LayoutSelfEnrolledStudentName />
                        </>}

                    </div>
                    <Menu
                        style={{ backgroundColor: " #900000" }}
                        theme="dark"
                        mode="inline"
                        selectedKeys={selected_menu}
                        items={[
                            {
                                key: '1',
                                icon: <UserOutlined />,
                                label: 'Personal Information',
                                onClick: function (e) {
                                        navigate('/')
                                    }
                            },

                            {
                                key: '2',
                                icon: <UserOutlined />,
                                label: 'Demographic Information',
                                onClick: function (e) {
                                    if(localStorage.getItem("personal_information_status") == 2)
                                        {
                                            navigate('/demographic-information')
                                        }
                                }
                            },
                            {
                                key: '3',
                                icon: <UserOutlined />,
                                label: 'Finance Declaration',
                                onClick: function (e) {
                                    if(localStorage.getItem("personal_information_status") == 2 && localStorage.getItem("demographic_information_status") == 2)
                                        {
                                            navigate('/finance-declaration')
                                        }
                                }
                            },

                            {
                                key: '4',
                                icon: <UserOutlined />,
                                label: 'Agreement',
                                onClick: function (e) {
                                    if(localStorage.getItem("personal_information_status") == 2 && localStorage.getItem("demographic_information_status") == 2 && localStorage.getItem("finance_declaration_information_status") == 2)
                                        {
                                            navigate('/agreement')
                                        }
                                }
                            },
                            {
                                key: '5',
                                icon: <UserOutlined />,
                                label: 'Preview',
                                onClick: function (e) {
                                    if(localStorage.getItem("personal_information_status") == 2 && localStorage.getItem("demographic_information_status") == 2 && localStorage.getItem("finance_declaration_information_status") == 2 && localStorage.getItem("agreement_information_status") == 2)
                                        {
                                            navigate('/formpreview')
                                        }
                                }
                            },
                            {
                                key: '6',
                                icon: <UserOutlined />,
                                label: 'Payment',
                                onClick: function (e) {
                                    if(localStorage.getItem("personal_information_status") == 2 && localStorage.getItem("demographic_information_status") == 2 && localStorage.getItem("finance_declaration_information_status") == 2 && localStorage.getItem("agreement_information_status") == 2)
                                        {
                                            navigate('/payment')
                                        }
                                }
                            },
                            {
                                key: '12',
                                icon: <LogoutOutlined />,
                                label: 'Logout',
                                onClick: function (e) {
                                    localStorage.clear();
                                    window.location = "https://uat-students.icore.uk/";
                                }
                            }
                        ]}
                    />
                </Sider>
                <Layout>
                    <Header style={{ padding: 0, background: colorBgContainer }}>
                        <Button
                            type="text"
                            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                            onClick={() => setCollapsed(!collapsed)}
                            style={{
                                fontSize: '16px',
                                width: 64,
                                height: 64,
                            }}
                        />
                    </Header>
                    <Content
                        style={{
                            margin: '24px 16px',
                            padding: 24,
                            minHeight: window.innerHeight,
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                    >
                        <Outlet />
                    </Content>
                </Layout>
            </Layout>
        </>
    );
}

export default EnrollmentApplicationFormLayout;
