import { Modal } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import logoTheme from '../../../images/fav.png';
import '../Header-and-Footer/customHeader.css'


const ShowPrivacyPolicy = () => {
    return (
        <>

            <div className="row" style={{ backgroundColor: "#900000" }}>
                <div className="col-4">
                    <div style={{ display: "flex", padding: "10px", alignItems: 'center' }}>
                        <img className='theme-logo' src={logoTheme} alt="Lincoln University" />
                        <h2 style={{ color: "#fff" }}>Enrollment Application</h2>
                    </div>
                </div>
            </div>
            <div className="row" style={{padding:"50px"}}>
            <h1>Privacy Policy</h1>
            <br />
            <h3>Who we are</h3>
            <p>Lincoln University Oakland California is a non-profit non secretarial educational institution founded by Dr. Benjamin Lickey in 1919 in San Francisco.</p><br />
            <p>Lincoln University, Oakland is committed to protecting the privacy and accuracy of confidential information to the extent possible. In particular, we do not re-distribute or sell personal information collected on our web servers.</p><br />
            <p>This website (apply.lincolnuca.edu) contains an enrollment application where applicants are encouraged to visit, learn and apply to get admission to Lincoln University.</p><br />
            <h3>What personal data we collect and why we collect it</h3>
            <p>We collect personal data of the users to help access features such as Application form. To become a member/applicant, a user has to provide data namely Name, Address, Phone, Email, Username and Password.</p><br />
            <p>The collected data are used within the application and is not shared with any other organization or entity.To apply to Lincoln University, the user or the applicant must provide credit card information to our payment gateways Stripe and PayPal. The credit card details never get accessed by our server, so there’s no need to worry about the security of your credit card details.</p><br />
            <p>This website uses webforms. Contact and personal information from these forms is used to send material relating to the purpose for which it was collected and will not be sold to another party. This website will only use personal information collected from this site for the purpose of communicating with individuals who contact us via the site.</p><br />
            <h3>Logins</h3>
            <p>The logins are used to allow submission or editing of content, information associating those submissions with that particular login is stored. Cookies and other Tracking Technologies This website uses cookies for session maintenance: they allow us to see which pages a given user visited, but do not have any identifying information about the user. Analytics partners.</p><br />
            <p>We partner with multiple third-party analytics providers to help us understand how visitors use our sites. We use this information to improve our content and your experience visiting our site. In order to generate this information, these analytics partners set their own cookies. We do not pass any personally identifying information about users to our partners. Google Analytics.</p><br />
            <p>How Google uses information from sites that use Google Analytics. Google does not combine, match or cross-reference information from Google Analytics with any other user information.</p><br />
            <h3>Media</h3>
            <p>The user or the applicant must provide their photo, along with academic documents like transcripts, banking documents and related files or images for determining the eligibility of admissions to Lincoln University. The media’s are secured and is not distributed and publicly disclosed to anyone, except for certain explicit circumstance in which disclosure is required by the law.</p><br />
            <h3>Distribution of collected information:</h3>
            <p>This website will not disclose, without your consent, personal information collected about you, except for certain explicit circumstances in which disclosure is required by law. This website will not distribute or sell personal information to third-party organizations.</p><br />
            <h3>Scope:</h3>
            <p>This Privacy Statement does not apply to any other Lincoln University web sites. You should review the privacy statement posted on other Lincoln University Website when you visit them.We may change this Privacy Statement at any time and for any reason. We encourage you to review this Privacy Statement each time you visit the website. If we make a significant change to our Privacy Statement, we will post a notice on the homepage of our web site for a period of time after the change is made.</p><br />
            <p>The privacy statement was last revised and posted on April 16, 2022.</p><br />
            <h3>How to Contact Us?</h3>
            <p>You may contact us at developer@lincolnuca.edu to know more about data privacy or incase you want to request a complete erase of your personal information from this system.</p><br />
            </div>
        </>
    );
}

export default ShowPrivacyPolicy;